import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import MenuItems from "../components/menuItems"

import YouTube from 'react-youtube';

const IndexPage = () => (
  <Layout>
    <SEO title="Classicurls - Home" keywords={[`classicurls`, `opera`, `zangeres`]} />
    
    <div className="pr-3 lg:pr-0">
      <Image />
    </div>
    
    <div className="relative -mt-12 lg:-mt-64 lg:-mr-8 flex flex-col items-end">
      <MenuItems alignLeft={false} />
    </div>

    <div className="lg:px-4 mt-16">
      <div className="lg:flex lg:-mx-4">
        <div className="w-full lg:w-1/3 lg:pr-4">
          <h2 className="text-sand-400 text-4xl lg:text-5xl leading-none uppercase mb-2 font-semibold">Over Classicurls</h2> 
          <p className="text-sand-100 text-justify mb-8 max-w-xl">
          Drie vrolijke krullenbollen die niet alleen kunnen zingen, maar ook nog eens een podium presence hebben waar je U tegen zegt. 
          Onder het motto “Waarom solo als het ook gezellig met z’n drieën kan” steken Mattanja, Lucie en Nienke klassiek repertoire in een hip jasje. 
          De operawereld een stoffig imago? Daar weten deze dames wel raad mee! Met een breed scala aan repertoire is dit trio in te zetten voor allerlei gelegenheden: van recepties tot dinnershows, van achtergrond tot avondvullend programma. Maak nu kennis met klassieke muziek, die tóch modern is.
          </p>     
        </div>

        <div className="lg:flex-1 lg:pl-4 lg:pt-48">
          <div className="videoWrapper">
            <YouTube videoId="13px4NxdPfk" className="absolute inset-0 w-full h-full"/>
          </div>
        </div>

      </div>
    </div>



  </Layout>
)

export default IndexPage